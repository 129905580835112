<template>
    <div class="about">
        <div class="member-head">
            <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
        </div>
        <div class="common">
            <div class="common-container MyAdress-container">
                <div class="MyAdress-content">
                    <div class="myAddress-icon">
                        <i class="iconfont icon-u855"></i>
                    </div>
                    <div class="address">
                        <div class="address-name">
                            <span>李光洙</span>
                            <span class="phone">13576698366</span>
                        </div>
                        <div class="address-time">
                            上海 上海市 嘉定区 江桥镇 金沙江西路 1555弄 372号
                        </div>
                    </div>
                </div>
                <div class="MyAdress-btn">
                    <div class="btn">
                        <button>修改</button>
                        <button>删除</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyAdress',
    methods: {
        goHistotry() {
            this.$router.go(-1)
        },
    },
}
</script>

<style lang="less" scoped>
@import '../assets/css/member.less';
</style>